<script setup>
import {useBaseStore} from "~/stores/base";
import {PROGRESS_STATUS} from "~/utils/constants";

const router = useRouter()
const route = useRoute()

/** emits **/
const emits = defineEmits([])

/** props **/
const props = defineProps({
  start: {
    default: 0,
    required: true
  },
  end: {
    default: 100,
    required: true
  },
  status: {
    default: PROGRESS_STATUS.PENDING
  },
  rate: {
    default: 50
  }
})

/** data **/
const percent = ref(props.start)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

const resetPercent = () => {
  percent.value = props.start
  const timer = setInterval(() => {
    if (percent.value === props.end) {
      clearInterval(timer)
      return
    }
    percent.value++
  }, props.rate)
}

onMounted(() => {
  resetPercent()
})
</script>

<template>
  <div class="module-loading-progress-mobile" v-if="isMobile">
    <div class="content">
      <template v-if="status === PROGRESS_STATUS.PENDING">
        <div class="animate-loading-wave"/>
        <div class="progress">
          <el-progress
            :show-text="false"
            :color="'#F1E8E4'"
            :stroke-width="8"
            :percentage="percent"
          />
          <div class="percent">{{percent}}%</div>
        </div>
      </template>
      <template v-if="status === PROGRESS_STATUS.SUCCESS">
        <img class="img" src="@/assets/img/icon-success.svg" alt="success">
        <div class="title">Payment successful</div>
      </template>
      <template v-if="status === PROGRESS_STATUS.FAILED">
        <img class="img" src="@/assets/img/icon-failed.svg" alt="failed">
        <div class="title">Payment failed</div>
      </template>
      <template v-if="status === PROGRESS_STATUS.ERROR">
        <div class="animate-loading-wave"/>
        <div class="progress">
          <el-progress
            :show-text="false"
            :color="'#F1E8E4'"
            :stroke-width="8"
            :percentage="percent"
          />
          <div class="percent">{{percent}}%</div>
        </div>
      </template>
      <slot></slot>
    </div>
  </div>
  <div class="module-loading-progress-desktop" v-else>
    <div class="content">
      <template v-if="status === PROGRESS_STATUS.PENDING">
        <div class="animate-loading-wave"/>
        <div class="progress">
          <el-progress
            :show-text="false"
            :color="'#F1E8E4'"
            :stroke-width="8"
            :percentage="percent"
          />
          <div class="percent">{{percent}}%</div>
        </div>
      </template>
      <template v-if="status === PROGRESS_STATUS.SUCCESS">
        <img class="img" src="@/assets/img/icon-success.svg" alt="success">
        <div class="title">Payment successful</div>
      </template>
      <template v-if="status === PROGRESS_STATUS.FAILED">
        <img class="img" src="@/assets/img/icon-failed.svg" alt="failed">
        <div class="title">Payment failed</div>
      </template>
      <template v-if="status === PROGRESS_STATUS.ERROR">
        <div class="animate-loading-wave"/>
        <div class="progress">
          <el-progress
            :show-text="false"
            :color="'#F1E8E4'"
            :stroke-width="8"
            :percentage="percent"
          />
          <div class="percent">{{percent}}%</div>
        </div>
      </template>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-loading-progress-mobile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .animate-loading-wave {
      width: 80px;
      height: 40px;

      background: url("@/assets/img/animate-loading-wave-frame-25.png") no-repeat;
      background-position-x: 0;
      background-position-y: 0;
      background-size: cover;
      animation: moveBackgroundMobile 2320ms steps(58) infinite;
      /*
        1000ms / 25 frame rate = 40ms;
        frame count = height 8700 / 150(pic per height) = 58
        58 frame count * 40ms = 2320ms
      */
    }

    @keyframes moveBackgroundMobile {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: -2320px;
      }
      /* 58 frame count * -40px(render per height) = -2320px */
    }

    .progress {
      display: flex;
      gap: 8px;
      margin-bottom: 18px;

      .el-progress--line {
        width: 180px;
      }

      .percent {
        font-family: Alternate Gothic No3 D;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 0px; /* 0% */
        padding-top: 4px;

        color: $color-gray-800;
      }
    }

    .img {
      width: 40px;
      height: 40px;
    }

    .title {
      text-align: center;
      font-family: "Alternate Gothic No3 D";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 109.091% */
      text-transform: uppercase;

      margin-top: 14px;
    }
  }
}

.module-loading-progress-desktop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .animate-loading-wave {
      width: 80px;
      height: 40px;

      background: url("@/assets/img/animate-loading-wave-frame-25.png") no-repeat;
      background-position-x: 0;
      background-position-y: 0;
      background-size: cover;
      animation: moveBackgroundMobile 2320ms steps(58) infinite;
      /*
        1000ms / 25 frame rate = 40ms;
        frame count = height 8700 / 150(pic per height) = 58
        58 frame count * 40ms = 2320ms
      */
    }

    @keyframes moveBackgroundMobile {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: -2320px;
      }
      /* 58 frame count * -40px(render per height) = -2320px */
    }

    .progress {
      display: flex;
      gap: 8px;
      margin-bottom: 18px;

      .el-progress--line {
        width: 180px;
      }

      .percent {
        font-family: Alternate Gothic No3 D;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 0px; /* 0% */
        padding-top: 4px;

        color: $color-gray-800;
      }
    }

    .img {
      width: 40px;
      height: 40px;
    }

    .title {
      text-align: center;
      font-family: "Alternate Gothic No3 D";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 109.091% */
      text-transform: uppercase;

      margin-top: 14px;
    }
  }
}
</style>
